
import React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { Link, } from 'gatsby';

const OtherPage = ({ pageContext }) => {
  const { categories, tags, } = pageContext;
  // console.log('dude wtf', pageContext);
  return (
    <React.Fragment>
      <SEO title="watcha looking for?" />
      <h2>What are you curious about?</h2>
      <ul>{categories.map((category) =>
        <li key={`category-${category._id}`}><Link to={`/category/${category.slug}`}>{category.name}</Link></li>
      )}
      </ul>
      <h2>Tags</h2>
      <ul>
        {tags.map((tag) =>
          (<li key={`tag-item-${tag}`}><Link to={`/tag/${tag}`}>{tag}</Link></li>)
        )}
      </ul>
    </React.Fragment>
  );
};

export default OtherPage;
